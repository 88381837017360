var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-template', [_c('v-layout', {
    attrs: {
      "justify-center": "",
      "align-start": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100wv"
    }
  }, [_c('div', {
    staticClass: "sub-visual-container sv1"
  }, [_c('div', {
    staticClass: "sub-visual-wrapper"
  }, [_c('h1', {
    staticClass: "sub-visual--title"
  }, [_vm._v("컨텍 페이지")])])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }