<template>
    <default-template>
        <v-layout justify-center align-start>
            <v-responsive width="100wv">
                <div class="sub-visual-container sv1">
                    <div class="sub-visual-wrapper">
                        <h1 class="sub-visual--title">컨텍 페이지</h1>
                    </div>
                </div>
            </v-responsive>
        </v-layout>
    </default-template>
</template>
<script>
import { mapState } from 'vuex';
import DefaultTemplate from "../DefaultTemplate.vue";
export default {
    components: {
        DefaultTemplate
    },
    computed: {
        ...mapState(["siteInfo"]),
    }
}
</script>
